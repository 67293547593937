import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import SEO from "../components/SEO";

const NotFound: React.FC = () => {
  return (
    <>
      <SEO title="404: Not found" noIndex />

      <Container>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ minHeight: "70vh" }}
        >
          <Grid item>
            <Typography variant="h1">Not Found</Typography>
          </Grid>
          <Grid item>
            <Typography>
              The page you are looking for does not exist.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default NotFound;
